import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTableContext } from "../../context/TableContext";
import html2pdf from "html2pdf.js";
import fng_logo from "../../assets/images/fng_logo.png";

export default function CustomerFieldCopyView() {
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    billingType: "",
    jobAddress: "",
    jobType: "",
    crewCategory: "",
    description: "",
    crew: [],
    truckNo: "",
    trailerNo: "",
    projectStartDate: "",
    status: "",
    credits: 0,
    projectCompletedDate: "",
    officeCopyId: "",
    bidCopyId: "",
    billAddress : ""
  });
  const [fieldCopies, setFieldCopies] = useState([]);
  const [jobType, setJobType] = useState("");
  const [loading, setLoading] = useState(false);
  const [laborTotal, setLaborTotal] = useState(0);
  const [materialsTotal, setMaterialsTotal] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [materialData, setMaterialData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [categorizedFieldCopies, setCategorizedFieldCopies] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [address, setAddress] = useState("");

  const location = useLocation();
  const data = location.state.data;

  console.log("Data", data)

  const { id, entryDate, index } = useParams();

  const navigate = useNavigate();
  const { tableSize } = useTableContext();

  const downloadPdf = () => {
    const element = document.getElementById("content-to-pdf");

    // Create a temporary div with the hidden content
    const tempDiv = document.createElement("div");
    // tempDiv.innerHTML = `
    //     <div class="flex justify-center mb-0">
    //       <img src="${fng_logo}" alt="F&G Logo" class="h-[100px]" />
    //     </div>
    //   `;

    // Insert the temporary div at the top of the content
    element.prepend(tempDiv);

    const fileName = documentName + ".pdf";

    const options = {
      margin: 0.3,
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      // .save()
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        // Ensure we're on the last page
        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setPage(pageCount);

        // Get page dimensions
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // console.log("Page details", pageCount, pageWidth, pageHeight);

        // Add footer text
        pdf.setFontSize(10);
        pdf.text(
          `Approved by: __________________  Date: ____________________

Approved by: __________________  Date: ____________________`,
          pageWidth / 30,
          pageHeight - 0.6,
          { align: "left" }
        );
        // pdf.text("", pageWidth/4, pageHeight-0.5, { align: "right" });

        // console.log("PDF", pdf);

        // Save the PDF with the footer added
        pdf.save(fileName);
      })
      .then(() => {
        // Ensure the temporary div is removed after the download completes
        tempDiv.remove();
      })
      .catch((error) => {
        console.error("PDF generation failed:", error);
        tempDiv.remove(); // Ensure cleanup even if an error occurs
      });
  };

  useEffect(() => {
    let taxAmount = 0;
    if (
      categorizedFieldCopies &&
      categorizedFieldCopies[0] &&
      categorizedFieldCopies[0]?.items?.length > 0
    ) {
      for (let type of categorizedFieldCopies[0].items) {
        if (type.isTaxable) {
          taxAmount +=
            Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
        }
      }
    }
    for (let labor of laborData) {
      if (labor.isLaborTaxable) {
        taxAmount += Number.parseFloat(labor.totalPrice);
      }
    }
    setTaxableAmount(taxAmount);
  }, [categorizedFieldCopies]);

  useEffect(() => {
    if (fieldCopies.length > 0) {
      const summarizedData = summarizeFieldCopies(fieldCopies);

      setCategorizedFieldCopies([
        { category: "Materials & Other", items: summarizedData },
      ]);
      const { laborTotal, materialsTotal } = calculateTotals(fieldCopies);
      setLaborTotal(laborTotal);
      setMaterialsTotal(materialsTotal);
    }
  }, [fieldCopies]);

  useEffect(() => {
    getProjectById();
    getJobTypeById();
    getTaxPercentage();
    getCustomerFieldCopyData();
    getFGAddress();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getJobTypeById();
  }, [formData]);

  const getProjectById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-project/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        if (!response.data.result.isProjectStarted) {
          navigate(-1);
        }
        setFormData(response.data.result);
        let doc_name = response?.data?.result?.customerName || "";
        if (doc_name.includes(" ")) {
          doc_name = doc_name.split(" ")[1] + "_" + doc_name.split(" ")[0];
        }
        setDocumentName(doc_name);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getCustomerFieldCopyData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-customer-copy/${id}/${entryDate}/${index}`,
        { headers: headers }
      );
      console.log(
        "Server Customer response",
        response.data.result.customerCopiesData
      );
      if (response.data.statusCode === 200) {
        let resultedCopies = [
          ...response.data.result.customerCopiesData,
          // ...response.data.result.officeDraftCopies,
        ];
        let resultedMaterials = [
          ...response.data.result.materialData,
          // ...response.data.result.materialDraftData,
        ];
        resultedMaterials = categorizeMaterial(resultedMaterials);
        // console.log("Material Data resulter", resultedMaterials);

        let resultedLabors = [
          ...response.data.result.laborData,
          // ...response.data.result.laborDraftData,
        ];
        resultedLabors = categorizeLabor(resultedLabors);
        console.log("-----------------------", resultedCopies);
        setFieldCopies(resultedCopies);
        setMaterialData(resultedMaterials);
        setLaborData(resultedLabors);

        // setFieldCopies(response.data.result.customerCopiesData || []);
        // setMaterialData(response.data.result.materialData || []);
        // setLaborData(response.data.result.laborData || []);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
    setLoading(false);
  };

  function categorizeLabor(laborData) {
    const categorizedData = laborData.reduce((result, item) => {
      // Create a unique key combining jobType and isLaborTaxable to handle distinctions
      const key = `${item.jobType}-${item.isLaborTaxable}`;

      if (!result[key]) {
        // Initialize a new entry for this jobType and tax status combination
        result[key] = {
          jobType: item.jobType,
          totalPrice: 0,
          isLaborTaxable: item.isLaborTaxable,
        };
      }
      // Sum up the totalPrice for the current jobType and tax status combination
      result[key].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  function categorizeMaterial(materialData) {
    const categorizedData = materialData.reduce((result, item) => {
      // Check if the jobType already exists in the result object
      if (!result[item.jobType]) {
        // Initialize a new entry for this jobType
        result[item.jobType] = {
          jobType: item.jobType,
          totalPrice: 0,
        };
      }
      // Sum up the totalPrice for the current jobType
      result[item.jobType].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  const getJobTypeById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      if (!formData.jobType) {
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-job-type/${formData.jobType}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setJobType(response.data.result.jobName);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getTaxPercentage = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/staff/get-tax-percent`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setTaxPercent(response.data.result.taxPercent);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const calculateTotals = (fieldCopies) => {
    const totals = {
      laborTotal: 0,
      materialsTotal: 0,
    };

    materialData.forEach((item) => {
      totals.materialsTotal += item.totalPrice;
    });

    laborData.forEach((item) => {
      totals.laborTotal += item.totalPrice;
    });

    return totals;
  };

  const summarizeFieldCopies = (fieldCopies) => {
    const summary = {};

    fieldCopies.forEach((item) => {
      const key = `${item.reference}-${item.measure}-${item.price}`;

      if (!summary[key]) {
        summary[key] = {
          source: item.source,
          isTaxable: item.isTaxable,
          reference: item.reference,
          description: item.description,
          size: item.measure,
          quantity: 0,
          price: item.price,
          totalPrice: 0,
        };
      }

      summary[key].quantity += item.quantity;
      summary[key].totalPrice += item.totalPrice;
    });

    // Calculate the total price
    // Object.keys(summary).forEach((key) => {
    //   summary[key].totalPrice = summary[key].quantity * summary[key].price;
    // });

    return Object.values(summary);
  };

  const getFGAddress = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get-fg-address`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setAddress(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  function formatDate(dateInput) {
    try {
      if (!dateInput) {
        return;
      }
      dateInput = Number.parseInt(dateInput, 10);
      const date = new Date(dateInput);
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date input");
      }

      const months = [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    } catch (error) {
      console.log("Error", error);
    }
  }

  const saveDocumentAs = () => {
    if (!documentName) {
      toast.error("Please enter document name.");
      return;
    }
    downloadPdf();
  };

  const updateDocumentName = (val) => {
    val = val?.replace(" ", "_");
    setDocumentName(val);
  };

  function convertToCentralTime(milliseconds) {
    // Convert milliseconds to a Date object

    milliseconds = Number.parseInt(milliseconds);

    const date = new Date(milliseconds);

    // Format the date to Central Time (US & Canada)
    let centralTime = date.toLocaleString("en-US", {
      // timeZone: "America/Chicago", // Central Time Zone
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return centralTime;
  }

  function convertMillisecondsToDate(ms) {
    ms = Number.parseInt(ms);
    const date = new Date(ms);

    // Extract month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year

    // Return in MM/DD/YY format
    return `${month}/${day}/${year}`;
}

  return (
    <Layout>
      <ToastContainer />
      <div
        className={`${tableSize === 250 ? "md:ml-[250px]" : "md:ml-[90px]"}`}
      >
        <div className="lg:p-10 p-3">
          <div className="card">
            <div className="card-header bg-[#00613e] text-white">
              <h3 className="card-title">View Customer Copy</h3>
            </div>

            {/* Modal For Save As */}
            <div
              className="modal fade"
              id="exampleModalCenter_saveAs"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Save Document As
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="documentName">Document Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="documentName"
                        placeholder="Enter Document Name"
                        value={documentName}
                        onChange={(e) => {
                          updateDocumentName(e.target.value);
                        }}
                        name="documentName"
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn bg-[#00613e] text-white"
                      data-dismiss="modal"
                      onClick={() => {
                        saveDocumentAs();
                      }}
                    >
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Download Copy */}
            <div
              className=""
              style={{
                display: "none",
              }}
            >
              <div className="p-0" id="content-to-pdf">

                
                {/* Project Data */}
                <div className="flex flex-row gap-3 justify-between mt-1">
                  <div className="flex flex-col w-1/3 md:w-[280px]">
                    <div className="p-0 capitalize">
                      {/* <h6 className="font-bold text-[15px]">Date</h6> */}
                      <p className="text-sm underline font-medium">
                        {/* {formData?.projectStartDate
                          ? formatDate(formData.projectStartDate)
                          : ""} */}
                        PROJECT LOCATION
                      </p>
                    </div>
                    <div className="p-0">
                      {/* <h6 className="font-bold text-[15px]">Customer Name</h6> */}
                      <p className="text-sm break-words capitalize">
                        {(formData?.customerName)?.toUpperCase()}
                      </p>
                    </div>
                    <div className="p-0 capitalize">
                      <p className="text-sm break-words">
                        {formData?.jobAddress?.toUpperCase()}
                      </p>
                    </div>
                    <div className="p-0 capitalize">
                    <h6 className="text-sm underline font-medium ">BILLING ADDRESS</h6>
                      <p className="text-sm break-words">
                        {formData?.billAddress?.toUpperCase()}
                      </p>
                    </div>

                    {/* <div className="p-0">
                      <h6 className="font-bold text-[15px]">Job Address</h6>
                      <p className="text-sm break-words">{formData?.jobAddress}</p>
                    </div> */}
                    {formData && formData?.customerEmail && (
                      <div className="p-0">
                        <p className="text-sm break-words">
                          {formData?.customerEmail}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-1/3 md:w-[280px]">
                    {/* F&G Logo */}
                    <div className="">
                      <div className="flex justify-center">
                        <img
                          src={fng_logo}
                          alt="F&G Logo"
                          className="h-[140px]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/3 md:w-[200px] text-end capitalize">
                    <div className="p-0">
                      <h6 className="font-bold text-[14px]">F&G INC</h6>
                      <pre
                        className="text-sm break-words p-0 pb-2 capitalize leading-4"
                        style={{ fontFamily: "Source Sans Pro" }}
                      >
                        {address}
                      </pre>
                    </div>
                    <div className="p-0">
                      <p className="text-sm break-words">
                        {data.uniqueId}
                      </p>
                    </div>
                    <div className="p-0">
                      {/* <h6 className="font-bold text-[15px]">Project Code</h6> */}
                      {formData?.projectCompletedDate && (
                        <p className="text-sm break-words">
                          {convertMillisecondsToDate(formData.projectCompletedDate)}
                        </p>
                      )}
                      <p className="text-sm break-words">
                        {formData.projectCode}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Description */}
                <div className="px-0.5">
                <h6 className="font-semibold text-[13px] tracking-wide capitalize underline">
                    {jobType?.toUpperCase()} PROJECT:
                  </h6>
                  <p className="text-sm break-words">{formData.description}</p>
                </div>

                {/* Field Copies */}
                <div className="w-full mt-2 text-[15px] overflow-x-auto pb-4">
                  {categorizedFieldCopies.length > 0 ? (
                    categorizedFieldCopies.map((group, index) => (
                      <div key={index} className="mb-0">
                        {/* <h4 className="font-bold text-[17px] mb-3">
                          {group.category}
                        </h4> */}
                        <table className="w-full text-sm">
                          <thead className="">
                            <tr>
                              {/* <th className="text-sm">
                                  <span className="relative -top-1.5">
                                    Source
                                  </span>
                                </th> */}
                              <th className="text-sm text-center">
                                <span className="relative -top-1.5">
                                  DESCRIPTION
                                </span>
                              </th>
                              <th className="text-sm">
                                <span className="relative -top-1.5">SIZE</span>
                              </th>
                              <th className="text-sm">
                                <span className="relative -top-1.5">
                                  QUANTITY
                                </span>
                              </th>
                              <th className="text-sm">
                                <span className="relative -top-1.5">PRICE</span>
                              </th>
                              <th className="text-sm text-end">
                                <span className="relative -top-1.5">TOTAL</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {group &&
                              group.items &&
                              group.items.length > 0 &&
                              group.items.map((item, idx) => (
                                <tr key={idx}>
                                  {/* <td className="text-sm">{item?.source}</td> */}
                                  <td className="text-sm">{item?.reference}</td>
                                  <td className="text-sm">{item?.size}</td>
                                  <td className="text-sm pl-4">
                                    {item?.quantity ? item.quantity : ""}
                                  </td>
                                  <td className="text-sm">
                                    {item?.price?.toFixed(2)}
                                  </td>
                                  <td className="text-sm text-end">
                                    {item?.totalPrice?.toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                            {/* <tr>
                                <td colSpan="3" className="font-bold">
                                </td>
                                <td>
                                  {group.items.reduce(
                                    (acc, item) => acc + item.quantity,
                                    0
                                  )}
                                </td>
                                <td>
                                  {group.items
                                    .reduce((acc, item) => acc + item.price, 0)
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {group.items
                                    .reduce(
                                      (acc, item) => acc + item.totalPrice,
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                              </tr> */}
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p className="pb-2">No field copies available.</p>
                  )}
                  <table className="w-full text-sm">
                    <tbody>
                      {laborData
                        .filter((labor) => labor.totalPrice !== 0)
                        .map((labor) => {
                          return (
                            <tr className="">
                              <td className="">
                                <p>{labor.jobType?.toUpperCase()} LABOR</p>
                              </td>
                              <td className="text-end">
                                {labor.totalPrice?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="mt-2">
                  <h4 className="text-[17px] font-semibold text-center mb-6">
                    INVOICE SUMMARY
                  </h4>
                </div>

                {/* Compiled data by Job Type */}
                <div className="mt-1 text-sm">
                  {materialData.map((material) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{material.jobType?.toUpperCase()} MATERIAL</b>
                        </span>
                        <span>
                          <b>$</b> {material.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
                  {laborData
                    .filter((labor) => labor.totalPrice !== 0)
                    .map((labor) => {
                      return (
                        <div className="flex justify-between mt-1">
                          <span>
                            <b>{labor.jobType?.toUpperCase()} LABOR</b>
                          </span>
                          <span>
                            <b>$</b> {labor.totalPrice?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                </div>

                {/* Invoice Summary */}
                <div className="w-full mt-2 text-[15px]">
                  <div className="text-sm">
                    {/* <div className="flex justify-between">
                      <span>Total Labor Cost</span>
                      <span>
                        <b>$</b> {laborTotal?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between my-1">
                      <span>Total Material Cost</span>
                      <span>
                        <b>$</b> {materialsTotal?.toFixed(2)}
                      </span>
                    </div> */}
                    <hr />
                    <div className="flex justify-between my-1 mb-2">
                      <span>SUBTOTAL</span>
                      <span>
                        <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>CREDITS</span>
                      <span>
                        <b>$</b> {formData.credits?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-1 mb-2">
                      <span>TAXABLE AMOUNT</span>
                      <span>
                        <b>$</b> {taxableAmount?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-1 mb-2">
                      <span>SALES TAX</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * (taxableAmount - formData.credits)) /
                          100
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-1">
                      <span>GRAND TOTAL</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * (taxableAmount - formData.credits)) /
                            100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-3 pb-1">
                    <div className="">
                      <span className="text-sm">Approved by</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                      <span className="text-sm ml-2">Date</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                    </div>
                    <div className="mt-2">
                      <span className="text-sm">Approved by</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                      <span className="text-sm ml-2">Date</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                    </div>
                  </div> */}
              </div>
            </div>

            {/* Real Copy */}
            <div className="mt-6 p-4 ">
              {/* Project Data */}
              <div className="flex flex-col md:flex-row gap-6 justify-around">
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Date</h6>
                    <p>
                      {formData?.projectStartDate
                        ? formatDate(formData.projectStartDate)
                        : ""}
                    </p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Customer Name</h6>
                    <p>{formData?.customerName}</p>
                  </div>
                  {formData && formData?.jobAddress && (
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Job Address</h6>
                      <p>{formData?.jobAddress}</p>
                    </div>
                  )}
                  {formData && formData?.customerEmail && (
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Email</h6>
                      <p>{formData?.customerEmail}</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Project Code</h6>
                    <p>{formData.projectCode}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Type</h6>
                    <p>{jobType}</p>
                  </div>
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">
                      Description of work
                    </h6>
                    <p>{formData.description}</p>
                  </div>
                </div>
                <div className="flex flex-col w-[300px]">
                  <div className="p-2">
                    <h6 className="font-bold text-[17px]">F&G INC</h6>
                    <pre
                      className="text-base break-words p-0 pb-3"
                      style={{ fontFamily: "Source Sans Pro" }}
                    >
                      {address}
                    </pre>
                  </div>
                </div>
              </div>

              {/* Field Copies */}
              <div className="w-full mt-10 text-[15px] overflow-x-auto">
                {!loading ? (
                  categorizedFieldCopies.length > 0 ? (
                    categorizedFieldCopies.map((group, index) => (
                      <div key={index} className="mb-8">
                        <h4 className="font-bold text-lg mb-3">
                          {group.category}
                        </h4>
                        <table className="w-full table table-striped text-start">
                          <thead className="bg-[#00613e] text-white">
                            <tr>
                              {/* <th>Source</th> */}
                              <th>Description</th>
                              <th>Size</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.items.map((item, idx) => (
                              <tr key={idx}>
                                {/* <td>{item.source}</td> */}
                                <td>{item.reference}</td>
                                <td>{item.size}</td>
                                <td>{item.quantity ? item.quantity : ""}</td>
                                <td>{item.price?.toFixed(2)}</td>
                                <td>{item.totalPrice?.toFixed(2)}</td>
                              </tr>
                            ))}
                             {laborData
                                .filter((labor) => labor.totalPrice !== 0)
                                .map((labor) => {
                                  return (
                                    <tr className="">
                                      <td className="">
                                        <p>{labor.jobType} Labor</p>
                                      </td>
                                      <td colSpan={3}></td>
                                      <td className="text-start">
                                        {labor.totalPrice?.toFixed(2)}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    ))
                  ) : (
                    <p className="pb-2">No field copies available.</p>
                  )
                ) : (
                  <p className="pb-2">Loading ...</p>
                )}
              </div>

              <div className="">
                  <h4 className="text-lg font-semibold text-center mb-6">
                    Invoice Summary
                  </h4>
                </div>

              {/* Compiled data by Job Type */}
              <div className="mt-6 mb-4">
                {materialData.map((material) => {
                  return (
                    <div className="flex justify-between mt-1 capitalize">
                      <span>
                        <b>{material.jobType} MATERIAL</b>
                      </span>
                      <span>
                        <b>$</b> {material.totalPrice?.toFixed(2)}
                      </span>
                    </div>
                  );
                })}
                {/* {laborData
                  .filter((labor) => labor.totalPrice !== 0)
                  .map((labor) => {
                    return (
                      <div className="flex justify-between mt-1">
                        <span>
                          <b>{labor.jobType} Labor</b>
                        </span>
                        <span>
                          <b>$</b> {labor.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })} */}
              </div>

              {/* Invoice Summary */}

              <div className="">
               
                <div className="">
                  {/* <div className="flex justify-between">
                    <span>Total Labor Cost</span>
                    <span>
                      <b>$</b> {laborTotal?.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between my-2">
                    <span>Total Material Cost</span>
                    <span>
                      <b>$</b> {materialsTotal?.toFixed(2)}
                    </span>
                  </div> */}
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>SubTotal</span>
                    <span>
                      <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>

              {!loading ? (
                formData && formData.credits < laborTotal + materialsTotal ? (
                  <div className="">
                    <div className="flex justify-between my-2">
                      <span>Credits</span>
                      <span>
                        <b>$</b> {formData.credits?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Taxable Amount</span>
                      <span>
                        <b>$</b> {taxableAmount?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Sales Tax</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * (taxableAmount - formData.credits)) /
                          100
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Grand Total</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * (taxableAmount - formData.credits)) /
                            100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="h-[150px] w-full flex justify-center items-center">
                    Please ensure the credits are less than the Sub Total.
                  </div>
                )
              ) : (
                <div className="h-[150px] w-full flex justify-center items-center">
                  Loading ...
                </div>
              )}
              <hr />

              {/* <div className="w-full mt-16 text-[15px]">
                <div className="">
                  <h4 className="text-lg font-semibold text-center mb-6">
                    Invoice Summary
                  </h4>
                </div>
                <div className="">
                  <div className="flex justify-between">
                    <span>Total Labor Cost</span>
                    <span>
                      <b>$</b> {laborTotal?.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between my-2">
                    <span>Total Material Cost</span>
                    <span>
                      <b>$</b> {materialsTotal?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>SubTotal</span>
                    <span>
                      <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Credits</span>
                    <span>
                      <b>$</b> {formData.credits?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Taxable Amount</span>
                    <span>
                      <b>$</b> {taxableAmount?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Sales Tax</span>
                    <span>
                      <b>$</b>{" "}
                      {(
                        (taxPercent * (taxableAmount - formData.credits)) /
                        100
                      )?.toFixed(2)}
                    </span>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>Grand Total</span>
                    <span>
                      <b>$</b>{" "}
                      {(
                        (taxPercent * (taxableAmount - formData.credits)) /
                          100 +
                        (materialsTotal + laborTotal)
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="w-full mb-10 text-[15px] flex justify-end flex-col md:flex-row gap-4 p-6">
              {/* {formData.status === "Ongoing" && (
                <button
                  className="bg-primary text-white py-1 px-6"
                  onClick={() => {
                    navigate(
                      `/panel/office/project/field-copy/customer/edit/${id}`
                    );
                  }}
                >
                  Edit
                </button>
              )} */}
              <button
                className={`bg-[#00613e] text-white py-1 px-6 md:mr-3 mr-0 ${
                  formData && formData.credits > laborTotal + materialsTotal
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter_saveAs"
                data-dismiss="modal"
                disabled={
                  formData && formData.credits > laborTotal + materialsTotal
                }
                // onClick={downloadPdf}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
