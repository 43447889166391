import React from 'react'
import Layout from '../../components/layout/Layout'
import LaborTable from '../../components/tables/labor/LaborTable'

export default function AllLabor() {

  return (
    <div>
        <Layout>
            <LaborTable />
        </Layout>
    </div>
  )
}
