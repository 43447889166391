import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout/Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTableContext } from "../../../context/TableContext";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import "../../../styles/scrollbar.css";

export default function AddFieldCopyForm() {
  const [forms, setForms] = useState([
    {
      source: "F&G",
      type: "",
      vendorName: "",
      markup: 0,
      reference: "",
      measure: "",
      quantity: "",
      price: "",
      totalCost: 0,
      totalPrice: 0,
      invoice: "",
      PO: "",
      isTaxable: false,
      startDate: Date.now(),
      endDate: Date.now(),
    },
  ]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [fieldJobType, setFieldJobType] = useState("");
  const [fieldJobCost, setFieldJobCost] = useState(0);
  const [totalLabors, setTotalLabors] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [adminTax, setAdminTax] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [totalManHours, setTotalManHours] = useState(0);
  const [isLabor, setIsLabor] = useState(true);
  const [entryDate, setEntryDate] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  // const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".relative")) {
        setDropdownVisibility(forms.map(() => false)); // Close all dropdowns
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [forms]);

  useEffect(() => {
    getProjectById();
    getMaterials();
    getJobTypes();
    getTaxPercentage();
    window.scrollTo(0, 0);
  }, []);

  // useEffect to update all forms' material type when fieldJobType changes
  // useEffect(() => {
  //   const updatedForms = forms.map((formData) => ({
  //     ...formData,
  //     type: fieldJobType,
  //   }));
  //   setForms(updatedForms);
  // }, [fieldJobType]);

  const { tableSize } = useTableContext();

  const handleJobTypeChange = (e) => {
    setFieldJobType(e.target.value);
    // const updatedForms = forms.map((formData) => ({
    //   ...formData,
    //   type: e.target.value,
    // }));
    // setForms(updatedForms);
    const jobTypePrice =
      Number.parseFloat(
        jobTypes.find((type) => type.jobName === e.target.value)?.price
      ) || 0;
    setFieldJobCost(jobTypePrice);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];

    if (name === "source") {
      updatedForms[index] = {
        ...updatedForms[index],
        [name]: value,
        type: value.includes("Lump Sum") ? value : fieldJobType,
        reference: "",
        measure: "",
        quantity: "",
        price: "",
        totalPrice: "",
        isTaxable: false,
      };
    }

    if (name === "type") {
      updatedForms[index] = {
        ...updatedForms[index],
        [name]: value,
        reference: "",
        measure: "",
        quantity: "",
        price: "",
        totalPrice: "",
        isTaxable: false,
      };
    }

    const updatedForm = { ...updatedForms[index], [name]: value };

    // if(name === "vendorName"){
    //   if(containsNumberOrSpecialChar(e.target.value)){
    //     toast.error("Vendor name cannot contain numbers or special characters.");
    //     return;
    //   }
    // }

    // if(name === "reference"){
    //   if(containsNumberOrSpecialChar(e.target.value)){
    //     toast.error("Material name cannot contain numbers or special characters.");
    //     return;
    //   }
    // }

    // if(name === "measure"){
    //   if(containsNumberOrSpecialChar(e.target.value)){
    //     toast.error("Measure cannot contain numbers or special characters.");
    //     return;
    //   }
    // }

    // Calculate total price if both price and quantity are filled
    if (name === "price" || name === "quantity") {
      const price = parseFloat(updatedForm.price) || 0;
      const quantity = parseFloat(updatedForm.quantity) || 0;
      if (updatedForm.source === "Other") {
        updatedForm.totalCost = price && quantity ? price * quantity : "";
        const isTax = updatedForm.isTaxable === "true" ? true : false;
        const markup = parseFloat(updatedForm.markup) || 0;

        if (isTax) {
          const intermediatePrice =
            updatedForm.totalCost + (markup * updatedForm.totalCost) / 100;
          updatedForm.totalPrice =
            intermediatePrice + (adminTax * intermediatePrice) / 100;
        } else {
          updatedForm.totalPrice =
            updatedForm.totalCost +
            (updatedForm.markup * updatedForm.totalCost) / 100;
        }
      } else {
        updatedForm.totalPrice = price && quantity ? price * quantity : "";
        updatedForm.totalCost = price && quantity ? price * quantity : "";
      }
    }

    if (name === "markup") {
      const isTax = updatedForm.isTaxable === "true" ? true : false;
      const markup = parseFloat(updatedForm.markup) || 0;
      if (isTax) {
        const intermediatePrice =
          updatedForm.totalCost + (markup * updatedForm.totalCost) / 100;
        updatedForm.totalPrice =
          intermediatePrice + (adminTax * intermediatePrice) / 100;
      } else {
        updatedForm.totalPrice =
          updatedForm.totalCost + (markup * updatedForm.totalCost) / 100;
      }
    }

    if (name === "isTaxable") {
      const isTax = updatedForm.isTaxable === "true" ? true : false;
      const markup = parseFloat(updatedForm.markup) || 0;

      if (isTax) {
        const intermediatePrice =
          updatedForm.totalCost + (markup * updatedForm.totalCost) / 100;
        updatedForm.totalPrice =
          intermediatePrice + (adminTax * intermediatePrice) / 100;
      } else {
        const intermediatePrice =
          updatedForm.totalCost + (markup * updatedForm.totalCost) / 100;
        updatedForm.totalPrice =
          intermediatePrice + (0 * intermediatePrice) / 100;
      }
      updatedForm.isTaxable = updatedForm.isTaxable === "true" ? true : false;
    }

    

    updatedForms[index] = updatedForm;
    setForms(updatedForms);
  };

  const handleMaterialChange = (e, index) => {
    // const materialName = e.target.value;
    const materialName = e;
    // console.log("Material Name", index, materialName);
    const material = materials.find(
      (material) => material.name === materialName
    );
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      reference: material.name,
      measure: material.measure,
      price: material.price,
      isTaxable: material.isTaxable,
      totalPrice:
        Number.parseFloat(material.price) *
        Number.parseFloat(forms[index].quantity),
    };
    setForms(updatedForms);

    // Close the dropdown for the current form
    const updatedVisibility = [...dropdownVisibility];
    updatedVisibility[index] = false;
    setDropdownVisibility(updatedVisibility);
  };

  const [dropdownVisibility, setDropdownVisibility] = useState(
    forms.map(() => false) // Initialize visibility for each form
  );
  const [searchTerm, setSearchTerm] = useState(""); // Track search term for filtering
  const dropdownRefs = useRef([]);

  const toggleDropdownVisibility = (index) => {
    const updatedVisibility = [...dropdownVisibility];

    // Reset all dropdowns to false except the one being toggled
    updatedVisibility.forEach((_, i) => {
      if (i !== index) updatedVisibility[i] = false;
    });

    // Toggle the current dropdown
    updatedVisibility[index] = !updatedVisibility[index];

    setDropdownVisibility(updatedVisibility);

    // Reset search term when opening a new dropdown
    if (updatedVisibility[index]) {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is not inside any of the dropdowns
      if (
        dropdownRefs.current.every((ref) => ref && !ref.contains(event.target))
      ) {
        setDropdownVisibility(forms.map(() => false)); // Close all dropdowns
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [forms]); // No need to include dropdownVisibility in the dependency array

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // console.log("Forms", forms);

  function containsNumberOrSpecialChar(text) {
    // Regular expression to check for numbers (0-9) or special characters
    const regex = /[0-9!@#$%^&*(),.?":{}|<>]/;

    // Test the text against the regex
    return regex.test(text);
  }

  const getTotalManHours = (startTime, endTime, totalPerson = 0) => {
    const startParts = startTime.split(":");
    const endParts = endTime.split(":");

    const startHours = Number.parseInt(startParts[0], 10);
    const startMinutes = Number.parseInt(startParts[1], 10);
    const endHours = Number.parseInt(endParts[0], 10);
    const endMinutes = Number.parseInt(endParts[1], 10);

    // Calculate total minutes for both times
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    // Calculate the difference in minutes
    let differenceInMinutes = endTotalMinutes - startTotalMinutes;

    // Ensure we have a non-negative difference
    if (differenceInMinutes < 0) {
      console.warn("End time is earlier than start time.");
      return 0; // or handle the case as needed
    }

    // Convert minutes back to hours
    const resultedHours = differenceInMinutes / 60;

    // Return the total man-hours
    return totalPerson * resultedHours;
  };

  const handleTimeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let manHours = 0;
    if (name === "startTime") {
      // start time should not be greater than end time
      // if (value >= endTime) {
      //   toast.error("Start time should not be greater than end time.");
      //   return;
      // }
      manHours = getTotalManHours(value, endTime, totalLabors);
      setStartTime(value);
    }
    if (name === "endTime") {
      // end time should not be less than start time
      // if (value <= startTime) {
      //   toast.error("End time should not be less than start time.");
      //   return;
      // }
      manHours = getTotalManHours(startTime, value, totalLabors);
      setEndTime(value);
    }

    setTotalManHours(manHours || 0);
  };

  const getTaxPercentage = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/staff/get-tax-percent`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setAdminTax(response.data.result.taxPercent);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getProjectById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-field-copy-timing/${id}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        if (!response.data.result.isProjectStarted) {
          navigate(-1);
        }
        setStartTime(response.data.result.startTime);
        setEndTime(response.data.result.endTime);
        setTotalManHours(response.data.result.totalManHours);
        setTotalLabors(response.data.result.totalLabors);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const addForm = () => {
    setForms([
      ...forms,
      {
        source: "F&G",
        type: "",
        vendorName: "",
        markup: 0,
        reference: "",
        measure: "",
        quantity: "",
        price: "",
        PO: "",
        invoice: "",
        totalPrice: "",
        isTaxable: "",
      },
    ]);
  };

  const deleteForm = (index) => {
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };

  const getMaterials = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-materials-dpd`,
        { headers: headers }
      );

      if (response.data.statusCode === 200) {
        setMaterials(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getJobTypes = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get-job-types-dpd`,
        { headers: headers }
      );

      if (response.data.statusCode === 200) {
        setJobTypes(response.data.result);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // const mergeLaborAndMaterial = (resultedForm) => {
  //   console.log("Handle Material",resultedForm);
  //   const isLaborExistInMaterial = resultedForm.some((form) => {
  //     console.log("Form Materials",form)
  //     console.log(form.jobType,fieldJobType,form.jobType === fieldJobType);
  //     return form.jobType === fieldJobType
  //   });
  //   if(isLaborExistInMaterial){
  //     resultedForm = resultedForm.map((form) => {
  //       if(form.jobType === fieldJobType){
  //         form.totalCost = totalManHours * fieldJobCost;
  //         form.isLaborTaxable = fieldJobTaxable;
  //       }
  //       return form;
  //     })
  //   }else{
  //     resultedForm = [...resultedForm, {
  //       jobType : fieldJobType,
  //       isLaborTaxable : fieldJobTaxable,
  //       totalCost : totalManHours * fieldJobCost,
  //       copies : [],
  //     }]
  //   }

  //   return resultedForm;
  // }

  const compileFormData = (forms) => {
    const compiled = {};

    forms.forEach((form) => {
      const { reference, measure, quantity, price, totalPrice, type } = form;
      // Use both reference and measure as the key
      const key = `${type}-${reference}`;

      if (compiled[key]) {
        compiled[key].quantity += parseFloat(quantity);
        compiled[key].totalPrice += parseFloat(totalPrice);
      } else {
        compiled[key] = {
          ...form,
          quantity: parseFloat(quantity),
          totalPrice: parseFloat(totalPrice),
        };
      }
    });

    return Object.values(compiled);
  };

  const groupByType = (compiledData, laborData) => {
    const groupedData = compiledData.reduce((acc, item) => {
      const { type, ...copyData } = item;

      // Find the existing group in the accumulator by jobType
      let existingGroup = acc.find((group) => group.jobType === type);

      // Find the corresponding labor information for the current type
      const laborInfo = laborData.find((labor) => labor.jobType === type);

      // Get labor cost and taxable status, default to 0 and false if not found
      const laborCost = laborInfo ? laborInfo.totalPrice : 0;
      const isLaborTaxable = laborInfo ? laborInfo.isLaborTaxable : false;

      if (existingGroup) {
        // Set the labor cost only once, not for every copy
        if (existingGroup.totalCost === 0) {
          existingGroup.totalCost = laborCost;
        }

        // If any labor is taxable, set the group as taxable
        if (isLaborTaxable) {
          existingGroup.isLaborTaxable = true;
        }

        // Add the current item's details to the copies array
        existingGroup.copies.push({ ...copyData, type });
      } else {
        // Create a new entry if the jobType does not exist in the accumulator
        acc.push({
          jobType: type,
          totalCost: laborCost, // Set the labor cost once
          isLaborTaxable: isLaborTaxable,
          copies: [{ ...copyData, type }],
        });
      }

      return acc;
    }, []); // Start with an empty array to accumulate the groups

    // Add any labor data that doesn't exist in compiledData
    laborData.forEach((labor) => {
      const { jobType, totalPrice, isLaborTaxable } = labor;

      // Check if this labor jobType already exists in groupedData
      if (!groupedData.find((group) => group.jobType === jobType)) {
        groupedData.push({
          jobType,
          totalCost: totalPrice,
          isLaborTaxable,
          copies: [], // No corresponding compiledData, so copies are empty
        });
      }
    });

    return groupedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const compiledForms = compileFormData(forms);
    const groupedForms = groupByType(compiledForms, []);
    console.log(
      "Forms",
      groupedForms,
      fieldJobType,
      fieldJobCost * totalManHours
    );
    // return;
    try {
      let isValidPrice = forms.some((form) => {
        return (
          Number.parseFloat(form.price) * Number.parseFloat(form.quantity) !==
            Number.parseFloat(form.totalPrice) && form.source === "F&G"
        );
      });

      if (isValidPrice) {
        toast.error("Please ensure all field copies have valid prices.");
        return;
      }
      if (forms.length === 0 && !isLabor) {
        toast.error("Please add some data.");
        return;
      }

      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
      };
      setDisableBtn(true);

      const totalLaborCost = totalManHours * fieldJobCost;

      // console.log("Formdata", groupedForms);
      // return

      const formdata = new FormData();
      formdata.append("entryDate", entryDate);
      formdata.append("startTime", startTime);
      formdata.append("endTime", endTime);
      formdata.append("jobType", fieldJobType);
      formdata.append("forms", JSON.stringify(groupedForms));
      formdata.append("totalLaborCost", totalLaborCost);
      formdata.append("isLabor", isLabor);
      formdata.append("totalManHours", totalManHours);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/project/add-field-copy/${id}`,
        formdata,
        {
          headers: headers,
        }
      );

      console.log("Server Response", response);

      if (response.data.statusCode === 201) {
        toast.success(response.data.message);
        navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error(error.response.message);
    }
    setDisableBtn(false);
  };

  return (
    <Layout>
      <div
        className={`${tableSize === 250 ? "md:ml-[250px]" : "md:ml-[90px]"}`}
      >
        <ToastContainer />
        <div className="lg:p-10 p-3">
          <div className="card">
            <div className="card-header bg-[#00613e] text-white">
              <h3 className="card-title">Add Field Copy</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="flex justify-end gap-4 mr-2">
                  <div className="form-group flex flex-col">
                    <label htmlFor="project">Date *</label>
                    <input
                      type="date"
                      id="entryDate"
                      name="entryDate"
                      className="border-[1px] border-[black] outline-none w-[140px] p-1 text-sm rounded-[2px]"
                      value={entryDate}
                      onChange={(e) => {
                        setEntryDate(e.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]} // Set max to today's date
                      required
                    />
                  </div>
                  <div className="">
                    <label htmlFor="jobType">Job Type *</label>
                    <br />
                    <select
                      name="fieldJobType"
                      onChange={handleJobTypeChange}
                      id="fieldJobType"
                      className="w-[150px] border-[1px] p-1 rounded-sm border-[black] outline-none"
                      value={fieldJobType}
                      // required
                    >
                      <option value="">Select Job Type</option>
                      {jobTypes
                        .filter((item) => {
                          return item.status === "Active";
                        })
                        .map((item, index) => (
                          <option key={index} value={item.jobName}>
                            {item.jobName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group flex flex-col">
                    <label htmlFor="project">Start Time *</label>
                    <input
                      type="time"
                      id="startTime"
                      name="startTime"
                      className="border-[1px] border-[black] outline-none w-[100px] p-1 text-sm rounded-[2px]"
                      value={startTime}
                      onChange={handleTimeChange}
                      // required
                    />
                  </div>
                  <div className="form-group flex flex-col">
                    <label htmlFor="project">End Time *</label>
                    <input
                      type="time"
                      id="endTime"
                      name="endTime"
                      className="border-[1px] border-[black] outline-none w-[100px] p-1 text-sm rounded-[2px]"
                      value={endTime}
                      min={startTime}
                      onChange={handleTimeChange}
                      // required
                    />
                  </div>
                  <div className="form-group flex flex-col">
                    <label htmlFor="project">Total Man Hours *</label>
                    <p>{totalManHours?.toFixed(2)}</p>
                  </div>
                </div>

                {forms.map((formData, index) => (
                  <div
                    key={index}
                    className="flex gap-x-16 justify-start flex-wrap mb-4 p-6 pt-8 shadow-md relative"
                  >
                    <div className="form-group flex flex-col">
                      <label htmlFor={`source-${index}`}>Source *</label>
                      <select
                        name="source"
                        onChange={(e) => handleInputChange(e, index)}
                        id={`source-${index}`}
                        className="border-b border-[grey] outline-none w-[180px]"
                        value={formData.source}
                        required
                      >
                        <option value="">Select Source</option>
                        <option value="F&G">F&G</option>
                        <option value="Other">Other</option>
                        <option value="Lump Sum">
                          Lump Sum
                        </option>
                      </select>
                    </div>

                    {formData.source === "F&G" && (
                      <>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`type-${index}`}>
                            Material Type *
                          </label>
                          <select
                            name="type"
                            onChange={(e) => handleInputChange(e, index)}
                            id={`type-${index}`}
                            className="border-b border-[grey] outline-none w-[180px]"
                            value={formData.type}
                            required
                            // disabled
                          >
                            <option value="">Select</option>
                            {/* <option value={fieldJobType}>{fieldJobType}</option> */}
                            {jobTypes
                              .filter((item) => {
                                return item.status === "Active";
                              })
                              .map((item, index) => (
                                <option key={index} value={item.jobName}>
                                  {item.jobName}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div
                          className="form-group flex flex-col w-[180px] relative cursor-pointer"
                          key={index}
                          ref={(el) => (dropdownRefs.current[index] = el)} // Assign ref to each dropdown
                        >
                          <label htmlFor={`reference-${index}`}>
                            Material Name *
                          </label>
                          <div className="relative">
                            <div
                              className=""
                              onClick={() => toggleDropdownVisibility(index)}
                            >
                              <input
                                type="text"
                                id={`materialName-${index}`}
                                name={`materialName-${index}`}
                                className="border-b border-[grey] outline-none w-[180px] text-base pr-3 cursor-pointer"
                                value={formData.reference}
                                readOnly
                                placeholder="Select Material Name"
                                required
                              />
                              <span className="absolute right-0 cursor-pointer">
                                <i className="fa fa-caret-down"></i>
                              </span>
                            </div>

                            {dropdownVisibility[index] && (
                              <div className="max-h-[400px] w-[200px] scrollbar-content overflow-y-auto absolute top-[100%] bg-[white] shadow-md mt-1 z-10">
                                {/* Search Input */}
                                <input
                                  type="text"
                                  className="w-full px-2 py-1 border-b"
                                  placeholder="Search material..."
                                  value={searchTerm}
                                  onChange={handleSearchChange}
                                />
                                {/* Filtered and Searched Materials */}
                                {materials
                                  .filter(
                                    (item) =>
                                      item.status === "Active" &&
                                      item.name
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                  )
                                  .map((material) => (
                                    <div
                                      key={material?._id}
                                      onClick={() => {
                                        handleMaterialChange(
                                          material.name,
                                          index
                                        );
                                        toggleDropdownVisibility(index); // Close dropdown
                                      }}
                                      className="text-sm hover:bg-[#e3e3e3] cursor-pointer p-2"
                                    >
                                      {material.name}
                                    </div>
                                  ))}

                                {/* No Results Found */}
                                {materials.filter(
                                  (item) =>
                                    item.status === "Active" &&
                                    item.name
                                      .toLowerCase()
                                      .includes(searchTerm.toLowerCase())
                                ).length === 0 && (
                                  <div className="p-2 text-gray-500 text-center text-sm">
                                    No materials found
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {formData.source === "Other" && (
                      <>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`vendorName-${index}`}>
                            Vendor Name
                          </label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`vendorName-${index}`}
                            name="vendorName"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.vendorName}
                            placeholder="Enter Vendor Name"
                            maxLength={50}
                            // required
                          />
                        </div>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`reference-${index}`}>
                            Material Name *
                          </label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`reference-${index}`}
                            name="reference"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.reference}
                            placeholder="Enter Name"
                            required
                          />
                        </div>
                      </>
                    )}

                    {formData.source.includes("Lump Sum") && (
                      <>
                        <div className="form-group flex flex-col hidden">
                          <label htmlFor={`type-${index}`}>Material Type</label>
                          <select
                            name="type"
                            id={`type-${index}`}
                            className="border-b border-[grey] outline-none w-[180px]"
                            value="Lump Sum"
                            required
                          >
                            <option value="">Select</option>
                            <option value="Lump Sum">
                              Lump Sum (Sales Tax Paid on Materials)
                            </option>
                          </select>
                        </div>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`type-${index}`}>Lump Sum Type</label>
                          <select
                            name="type"
                            id={`type-${index}`}
                            className="border-b border-[grey] outline-none w-[180px]"
                            value={formData.type}
                            onChange={(e) => handleInputChange(e, index)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="Lump Sum">
                              Lump Sum
                            </option>
                            <option value="Drainage Lump Sum">
                              Drainage Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Electrical Lump Sum">
                              Electrical Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Hardscape Lump Sum">
                              Hardscape Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Irrigation Lump Sum">
                              Irrigation Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Landscape Lump Sum">
                              Landscape Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Mosquito Lump Sum">
                              Mosquito Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Plumbing Lump Sum">
                              Plumbing Lump Sum (Sales Tax Paid on Materials)
                            </option>
                            <option value="Pool Lump Sum">Pool Lump Sum (Sales Tax Paid on Materials)</option>
                          </select>
                        </div>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`vendorName-${index}`}>
                            Vendor Name
                          </label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`vendorName-${index}`}
                            name="vendorName"
                            onChange={(e) => handleInputChange(e, index)}
                            maxLength={50}
                            value={formData.vendorName}
                            placeholder="Enter Vendor Name"
                            // required
                          />
                        </div>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`reference-${index}`}>
                            Description *
                          </label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`reference-${index}`}
                            name="reference"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.reference}
                            maxLength={100}
                            placeholder="Enter Description"
                            required
                          />
                        </div>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`source-${index}`}>Taxable *</label>
                          <select
                            name="isTaxable"
                            onChange={(e) => handleInputChange(e, index)}
                            id={`isTaxable-${index}`}
                            className="border-b border-[grey] outline-none w-[180px]"
                            value={formData.isTaxable}
                            required
                          >
                            <option value="">Select Option</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </>
                    )}

                    {["F&G", "Other"].includes(formData.source) && (
                      <>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`measure-${index}`}>Measure *</label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`measure-${index}`}
                            name="measure"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.measure}
                            placeholder="Enter measure"
                            readOnly={
                              formData.source === "Other" ? false : true
                            }
                            required
                          />
                        </div>

                        <div className="form-group flex flex-col">
                          <label htmlFor={`price-${index}`}>Price *</label>
                          <input
                            type="number"
                            className="border-b border-[grey] outline-none"
                            id={`price-${index}`}
                            name="price"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.price}
                            placeholder="Enter Price"
                            // readOnly={
                            //   formData.source === "Other" ? false : true
                            // }
                            min={0}
                            max={10000000}
                            step="any"
                            required
                          />
                        </div>

                        <div className="form-group flex flex-col">
                          <label htmlFor={`quantity-${index}`}>
                            Quantity *
                          </label>
                          <input
                            type="number"
                            className="border-b border-[grey] outline-none w-[180px]"
                            id={`quantity-${index}`}
                            name="quantity"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.quantity}
                            placeholder="Enter Quantity"
                            min={0}
                            max={10000000}
                            step="any"
                            required
                          />
                        </div>
                      </>
                    )}

                    {formData.source === "Other" && (
                      <>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`totalCost-${index}`}>
                            Total Cost *
                          </label>
                          <input
                            type="number"
                            className="border-b border-[grey] outline-none"
                            id={`totalCost-${index}`}
                            name="totalCost"
                            placeholder="Total cost goes here ..."
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.totalCost}
                            min={0}
                            required
                            readOnly
                          />
                        </div>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`markup-${index}`}>Mark up *</label>
                          <input
                            type="number"
                            className="border-b border-[grey] outline-none w-[180px]"
                            id={`markup-${index}`}
                            name="markup"
                            placeholder="Enter percent"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData?.markup}
                            min={0}
                            max={100}
                            required
                          />
                        </div>
                        <div className="form-group flex flex-col">
                          <label htmlFor={`source-${index}`}>Taxable *</label>
                          <select
                            name="isTaxable"
                            onChange={(e) => handleInputChange(e, index)}
                            id={`isTaxable-${index}`}
                            className="border-b border-[grey] outline-none w-[180px]"
                            value={formData.isTaxable}
                            required
                          >
                            <option value="">Select Option</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`invoice-${index}`}>Invoice</label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`invoice-${index}`}
                            name="invoice"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.invoice}
                            placeholder="Enter invoice"
                            // required
                          />
                        </div>
                        <div className="form-group flex flex-col w-[180px]">
                          <label htmlFor={`PO-${index}`}>P.O.</label>
                          <input
                            type="text"
                            className="border-b border-[grey] outline-none"
                            id={`PO-${index}`}
                            name="PO"
                            onChange={(e) => handleInputChange(e, index)}
                            value={formData.PO}
                            placeholder="Enter PO"
                            // required
                          />
                        </div>
                      </>
                    )}

                    {["F&G", "Other"].includes(formData.source) ? (
                      <div className="form-group flex flex-col">
                        <label htmlFor={`totalPrice-${index}`}>
                          Total Price *
                        </label>
                        <input
                          type="number"
                          className="border-b border-[grey] outline-none"
                          id={`totalPrice-${index}`}
                          name="totalPrice"
                          placeholder="Total price goes here..."
                          value={formData.totalPrice}
                          readOnly
                          min={0}
                          step="any"
                          required
                        />
                      </div>
                    ) : (
                      <div className="form-group flex flex-col">
                        <label htmlFor={`totalPrice-${index}`}>
                          Total Price *
                        </label>
                        <input
                          type="number"
                          className="border-b border-[grey] outline-none"
                          id={`totalPrice-${index}`}
                          name="totalPrice"
                          placeholder="Total price goes here..."
                          value={formData.totalPrice}
                          max={10000000}
                          step="any"
                          // readOnly
                          onChange={(e) => handleInputChange(e, index)}
                          // min={0}
                          required
                        />
                      </div>
                    )}

                    {formData.reference && formData.source === "F&G" && (
                      <div className="form-group flex flex-col">
                        <label htmlFor={`isTaxable-${index}`}>Taxable</label>
                        <input
                          type="text"
                          className="border-b border-[grey] outline-none"
                          id={`isTaxable-${index}`}
                          name="isTaxable"
                          onChange={(e) => handleInputChange(e, index)}
                          value={formData.isTaxable ? "Yes" : "No"}
                          placeholder="Enter isTaxable"
                          disabled={true}
                        />
                      </div>
                    )}

                    <button
                      type="button"
                      className="absolute top-3 right-5 bg-red-500 h-[30px] w-[30px] rounded-full"
                      onClick={() => deleteForm(index)}
                    >
                      <i className="fa fa-close text-white"></i>
                    </button>
                  </div>
                ))}

                <div className="text-center">
                  <button
                    type="button"
                    onClick={addForm}
                    className="bg-green-500 text-white h-[35px] w-[35px] rounded-full"
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              {isLabor && (
                <div className="my-10 flex">
                  <table className="w-[100%] text-center">
                    <thead>
                      <tr>
                        <th>Labor Count</th>
                        <th>Total Man Hours</th>
                        <th>Per Hour Cost</th>
                        <th>Total Cost</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{totalLabors ? parseFloat(totalLabors) : 0}</td>
                        <td>{totalManHours ? parseFloat(totalManHours) : 0}</td>
                        <td>{fieldJobCost ? parseFloat(fieldJobCost) : 0}</td>
                        <td>{totalManHours * fieldJobCost}</td>
                        <td>
                          <button
                            type="button"
                            className="bg-red-500 h-[25px] w-[25px] rounded-full"
                            onClick={() => setIsLabor(!isLabor)}
                          >
                            <i className="fa fa-close text-white"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <div className="card-footer">
                <button
                  type="submit"
                  className="btn bg-[#00613e] text-white"
                  disabled={disableBtn}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
