import React from 'react'
import Layout from '../../components/layout/Layout'
import AllStaffsTable from '../../components/tables/staff/AllStaffsTable'

export default function AllStaffs() {

  return (
    <div>
        <Layout>
            <AllStaffsTable />
        </Layout>
    </div>
  )
}
