import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright © 2022-2024 F&G. </strong>
        All rights reserved.
        {/* <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div> */}
      </footer>
    </div>
  );
}
