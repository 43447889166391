import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTableContext } from "../../context/TableContext";
import html2pdf from "html2pdf.js";
import fng_logo from "../../assets/images/fng_logo.png";

export default function CustomerCompiledProjectsView() {
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    billingType: "",
    jobAddress: "",
    jobType: "",
    crewCategory: "",
    description: "",
    crew: [],
    truckNo: "",
    trailerNo: "",
    projectStartDate: "",
    status: "",
  });
  const [fieldCopies, setFieldCopies] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [jobType, setJobType] = useState("");
  const [creditAmount, setCreditAmount] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [materialsTotal, setMaterialsTotal] = useState(0);
  const [taxPercent, setTaxPercent] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [materialData, setMaterialData] = useState([]);
  const [laborData, setLaborData] = useState([]);
  const [categorizedFieldCopies, setCategorizedFieldCopies] = useState([]);
  const [hideFieldCopy, setHideFieldCopy] = useState(true);
  const [jobAddress, setJobAddress] = useState("");
  const [description, setDescription] = useState("");
  const [fgAddress, setFGAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const { id } = useParams();

  const location = useLocation();
  const selectedProjects = location.state.data;

  const navigate = useNavigate();
  const { tableSize } = useTableContext();

  const downloadPdf = () => {
    if (!jobAddress?.trim()) {
      toast.error("Please enter job address");
      return;
    }
    if (!description) {
      toast.error("Please enter description");
      return;
    }
    setHideFieldCopy(false);
    const element = document.getElementById("content-to-pdf");

    // // Create a temporary div with the hidden content
    // const tempDiv = document.createElement("div");
    // tempDiv.innerHTML = `
    //     <div class="flex justify-center mb-4">
    //       <img src="${fng_logo}" alt="F&G Logo" class="h-[100px]" />
    //     </div>
    //   `;

    // // Insert the temporary div at the top of the content
    // element.prepend(tempDiv);

    // const fileName = (formData && formData?.customerName) ? formData?.customerName?.replace(" ","_")+'.pdf' : "document.pdf"

    const fileName = documentName + ".pdf";

    const options = {
      margin: 0.1,
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      // .save()
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        // Ensure we're on the last page
        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setPage(pageCount);

        // Get page dimensions
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // console.log("Page details", pageCount, pageWidth, pageHeight);

        // Add footer text
        pdf.setFontSize(10);
        pdf.text(
          `Approved by: __________________  Date: ____________________

Approved by: __________________  Date: ____________________`,
          pageWidth / 30,
          pageHeight - 0.6,
          { align: "left" }
        );
        // pdf.text("", pageWidth/4, pageHeight-0.5, { align: "right" });

        // console.log("PDF", pdf);

        // Save the PDF with the footer added
        pdf.save(fileName);
      })
      .then(() => {
        // Ensure the temporary div is removed after the download completes
        // tempDiv.remove();
        setHideFieldCopy(true);
      })
      .catch((error) => {
        console.error("PDF generation failed:", error);
        // tempDiv.remove(); // Ensure cleanup even if an error occurs
        setHideFieldCopy(true);
      });
  };

  useEffect(() => {
    let taxAmount = 0;
    if (
      categorizedFieldCopies &&
      categorizedFieldCopies[0] &&
      categorizedFieldCopies[0]?.items?.length > 0
    ) {
      for (let type of categorizedFieldCopies[0].items) {
        if (type.isTaxable) {
          taxAmount +=
            Number.parseFloat(type.price) * Number.parseFloat(type.quantity);
        }
      }
    }
    for (let labor of laborData) {
      if (labor.isLaborTaxable) {
        taxAmount += Number.parseFloat(labor.totalPrice);
      }
    }
    setTaxableAmount(taxAmount);
  }, [categorizedFieldCopies]);

  useEffect(() => {
    if (fieldCopies.length > 0) {
      const summarizedData = summarizeFieldCopies(fieldCopies);

      setCategorizedFieldCopies([
        { category: "Materials & Other", items: summarizedData },
      ]);
      const { laborTotal, materialsTotal } = calculateTotals(fieldCopies);
      setLaborTotal(laborTotal);
      setMaterialsTotal(materialsTotal);
    }
  }, [fieldCopies]);

  useEffect(() => {
    getProjectByIds();
    getJobTypeById();
    getTaxPercentage();
    getCustomerFieldCopyData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getJobTypeById();
  }, [formData]);

  const getProjectByIds = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-compiled-projects`,
        { projectIds: JSON.stringify(selectedProjects) },
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        let allProjects = [];
        let allCustomerCopies = [];
        let allMaterialData = [];
        let allLaborData = [];
        let projectData = [];
        let credits = 0;
        for (let project of response.data.result) {
          if (project.customerCopiesData.length > 0) {
            const resultedCopies = [
              ...project.customerCopiesData,
              // ...project.officeDraftCopies,
            ];
            const summarizedData = summarizeFieldCopies(resultedCopies);

            // const categorizedFieldCopies = [
            //   { category: "Materials & Other", items: summarizedData },
            // ];

            let resultedMaterials = [
              ...project.materialData,
              // ...project.materialDraftData,
            ];
            let resultedLabors = [
              ...project.laborData,
              // ...project.laborDraftData,
            ];

            resultedMaterials = categorizeMaterial(resultedMaterials);
            resultedLabors = categorizeLabor(resultedLabors);

            allProjects = [
              ...allProjects,
              {
                copies: summarizedData,
                laborData: resultedLabors,
                materialData: resultedMaterials,
              },
            ];
          }
          allCustomerCopies = [
            ...allCustomerCopies,
            ...project.customerCopiesData,
            // ...project.officeDraftCopies,
          ];
          allMaterialData = [
            ...allMaterialData,
            ...project.materialData,
            // ...project.materialDraftData,
          ];
          allLaborData = [
            ...allLaborData,
            ...project.laborData,
            // ...project.laborDraftData,
          ];
          credits += project.projectData.credits;
          projectData = [...projectData, project.projectData];
        }
        const resultedMaterials = categorizeMaterial(allMaterialData);
        const resultedLabors = categorizeLabor(allLaborData);

        const allJobAddressesEqual = projectData.every(
          (item) => item.jobAddress === projectData[0].jobAddress
        );
        if (allJobAddressesEqual) {
          setJobAddress(projectData[0]?.jobAddress);
        } else {
          setJobAddress("");
        }

        setCreditAmount(credits);
        setAllProjects(allProjects);
        setProjectData(projectData);

        // allCustomerCopies.push(...project.customerCopiesData);

        // console.log(
        //   "All Projects Data",
        //   allCustomerCopies,
        //   allMaterialData,
        //   allLaborData
        // );
        // setFormData(response.data.result);
        setFieldCopies(allCustomerCopies || []);
        setMaterialData(resultedMaterials || []);
        setLaborData(resultedLabors || []);
        // setFieldCopies(response.data.result.customerCopiesData || []);
        // setMaterialData(response.data.result.materialData || []);
        // setLaborData(response.data.result.laborData || []);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  function categorizeLabor(laborData) {
    const categorizedData = laborData.reduce((result, item) => {
      // Create a unique key combining jobType and isLaborTaxable to handle distinctions
      const key = `${item.jobType}`;

      if (!result[key]) {
        // Initialize a new entry for this jobType and tax status combination
        result[key] = {
          jobType: item.jobType,
          totalPrice: 0,
          isLaborTaxable: item.isLaborTaxable,
        };
      }
      // Sum up the totalPrice for the current jobType and tax status combination
      result[key].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  function categorizeMaterial(materialData) {
    const categorizedData = materialData.reduce((result, item) => {
      // Check if the jobType already exists in the result object
      if (!result[item.jobType]) {
        // Initialize a new entry for this jobType
        result[item.jobType] = {
          jobType: item.jobType,
          totalPrice: 0,
        };
      }
      // Sum up the totalPrice for the current jobType
      result[item.jobType].totalPrice += item.totalPrice;
      return result;
    }, {});

    // Convert the result object to an array format
    return Object.values(categorizedData);
  }

  const getCustomerFieldCopyData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-customer-project-info/${selectedProjects[0]}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setFormData(response.data.result);
        let doc_name = response?.data?.result?.customerName || "";

        // Create doc_name like customer name = last name + "_" + firstName
        if (doc_name.includes(" ")) {
          doc_name = doc_name.split(" ")[1] + "_" + doc_name.split(" ")[0];
        }
        setDocumentName(doc_name);
        setFGAddress(response.data.result.companyAddress);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
    setLoading(false);
  };

  const getJobTypeById = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      if (!formData.jobType) {
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/get-job-type/${formData.jobType}`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setJobType(response.data.result.jobName);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const getTaxPercentage = async () => {
    try {
      const token = localStorage.getItem("f&gstafftoken");
      const headers = {
        token: token,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/staff/get-tax-percent`,
        { headers: headers }
      );
      if (response.data.statusCode === 200) {
        setTaxPercent(response.data.result.taxPercent);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.message);
    }
  };

  const calculateTotals = (fieldCopies) => {
    const totals = {
      laborTotal: 0,
      materialsTotal: 0,
    };

    materialData.forEach((item) => {
      totals.materialsTotal += item.totalPrice;
    });

    laborData.forEach((item) => {
      totals.laborTotal += item.totalPrice;
    });

    return totals;
  };

  const summarizeFieldCopies = (fieldCopies) => {
    const summary = {};

    fieldCopies.forEach((item) => {
      const key = `${item.reference}-${item.measure}-${item.price}`;

      if (!summary[key]) {
        summary[key] = {
          source: item.source,
          isTaxable: item.isTaxable,
          reference: item.reference,
          description: item.description,
          size: item.measure,
          quantity: 0,
          price: item.price,
          totalPrice: 0,
        };
      }

      summary[key].quantity += item.quantity;
      summary[key].totalPrice += item.totalPrice;
    });

    // Calculate the total price
    // Object.keys(summary).forEach((key) => {
    //   summary[key].totalPrice = summary[key].quantity * summary[key].price;
    // });

    return Object.values(summary);
  };

  function formatDate(dateInput) {
    try {
      if (!dateInput) {
        return;
      }
      dateInput = Number.parseInt(dateInput, 10);
      const date = new Date(dateInput);
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date input");
      }

      const months = [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    } catch (error) {
      console.log("Error", error);
    }
  }

  const saveDocumentAs = () => {
    if (!documentName) {
      toast.error("Please enter document name.");
      return;
    }
    downloadPdf();
  };

  const updateDocumentName = (val) => {
    val = val?.replace(" ", "_");
    setDocumentName(val);
  };

  function convertToCentralTime(milliseconds) {
    // Convert milliseconds to a Date object

    milliseconds = Number.parseInt(milliseconds);

    const date = new Date(milliseconds);

    // Format the date to Central Time (US & Canada)
    let centralTime = date.toLocaleString("en-US", {
      // timeZone: "America/Chicago", // Central Time Zone
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return centralTime;
  }

  return (
    <Layout>
      <ToastContainer />
      <div
        className={`${tableSize === 250 ? "md:ml-[250px]" : "md:ml-[90px]"}`}
      >
        <div className="lg:p-10 p-3">
          <div className="card">
            <div className="card-header bg-[#00613e] text-white">
              <h3 className="card-title">View Compiled Data</h3>
            </div>

            {/* Modal For Save As */}
            <div
              className="modal fade"
              id="exampleModalCenter_saveAs"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Save Document As
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="documentName">Document Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="documentName"
                        placeholder="Enter Document Name"
                        value={documentName}
                        onChange={(e) => {
                          updateDocumentName(e.target.value);
                        }}
                        name="documentName"
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn bg-[#00613e] text-white"
                      data-dismiss="modal"
                      onClick={() => {
                        saveDocumentAs();
                      }}
                    >
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className=""
              style={{
                display: "none",
              }}
            >
              <div className="p-2" id="content-to-pdf">
                <div className="text-center mb-4">
                  <h5 className="underline text-[red] tracking-wide">
                    COMPILED COPY
                  </h5>
                </div>
                {/* Project Data */}
                {true ? (
                  <div className="flex flex-row gap-3 justify-around">
                    <div className="flex flex-col w-1/3 md:w-[280px]">
                      <div className="px-0.5 capitalize">
                        <h6 className="text-[13px] font-semibold underline">
                          PROJECT LOCATION
                        </h6>
                        <input
                          type="text"
                          value={jobAddress}
                          className="border-0 border-black outline-none tracking-wide bg-transparent h-[30px] pb-1 md:text-sm text-xs w-full"
                          onChange={(e) => {
                            setJobAddress(e.target.value);
                          }}
                        />
                      </div>
                      <div className="px-0.5 capitalize m-0">
                        <h6 className="text-[13px] font-semibold">Bill To</h6>
                        {/* <h6 className="font-bold text-[15px]">Customer Name</h6> */}
                        <p className="md:text-sm text-xs break-words">
                          {formData?.customerName?.toUpperCase()}
                        </p>
                      </div>
                      <div className="px-0.5 -my-1">
                        {/* <h6 className="font-bold text-[15px]">Email</h6> */}
                        <p className="md:text-sm text-xs break-words m-0">
                          {formData?.customerEmail}
                        </p>
                      </div>

                      <div className="px-0.5 m-0">
                        {/* <h6 className="font-bold text-[15px]">Email</h6> */}
                        <p className="md:text-sm text-xs break-words">
                          {description}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/3 md:w-[280px]">
                      <div className="p-1">
                        <img
                          src={fng_logo}
                          className="h-[140px]"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-1/3 md:w-[280px] text-end capitalize">
                      <div className="p-0.5">
                        <h6 className="font-bold md:text-[15px] text-sm">
                          F&G INC
                        </h6>
                        <pre
                          className="text-sm break-words p-0 pb-3 leading-4"
                          style={{ fontFamily: "Source Sans Pro" }}
                        >
                          {fgAddress}
                        </pre>
                      </div>
                      <div className="px-0.5">
                        {/* <h6 className="font-bold text-[15px]">
                          Customer Phone
                        </h6> */}
                        <p className="md:text-sm text-xs break-words">
                          {formData?.customerPhone}
                        </p>
                      </div>
                      {/* <div className="p-1">
                        <h6 className="font-bold text-[15px]">Date</h6>
                        <p className="text-sm">{formatDate(Date.now())}</p>
                      </div> */}
                      <div className="px-0.5">
                        {/* <h6 className="font-bold text-[15px]">Project Codes</h6> */}
                        {projectData.map((data) => {
                          return (
                            <p key={data._id} className="md:text-sm text-xs">
                              {data.projectCode}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col md:flex-row gap-6 justify-around">
                    <div className="flex flex-col w-[300px]">
                      {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Date</h6>
                    <p>
                      {formData?.projectStartDate
                        ? formatDate(formData.projectStartDate)
                        : ""}
                    </p>
                  </div> */}
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">Customer Name</h6>
                        <p>{formData?.customerName}</p>
                      </div>
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">Job Address</h6>
                        <input
                          type="text"
                          // placeholder="Enter job address"
                          value={jobAddress}
                          className="border-0 border-black outline-none mt-1.5 tracking-wide bg-transparent h-[40px] pb-1"
                          onChange={(e) => {
                            setJobAddress(e.target.value);
                          }}
                        />
                      </div>
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">
                          Description of work
                        </h6>
                        <textarea
                          value={description}
                          className="border-0 border-black outline-none resize-none bg-transparent w-[400px] mt-1.5 tracking-wide"
                          // placeholder="Enter description"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        ></textarea>
                        {/* <input type="text" placeholder="Enter job address" value= className="border-b border-black outline" /> */}
                      </div>
                    </div>
                    <div className="flex flex-col w-[300px]">
                      {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Project Code</h6>
                    <p>{formData.projectCode}</p>
                  </div> */}
                      {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Type</h6>
                    <p>Lnadsc</p>
                  </div> */}
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">Email</h6>
                        <p>{formData?.customerEmail}</p>
                      </div>
                    </div>
                    <div className="flex flex-col w-[300px]">
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">F&G INC</h6>
                        <pre
                          className="text-base break-words p-0 pb-3"
                          style={{ fontFamily: "Source Sans Pro" }}
                        >
                          {fgAddress}
                        </pre>
                      </div>
                    </div>
                  </div>
                )}

                {/* Compiled data */}
                <>
                  <div className="w-full mt-4 text-[15px] overflow-x-auto pb-6">
                    {categorizedFieldCopies.length > 0 ? (
                      categorizedFieldCopies.map((group, index) => (
                        <div key={index} className="mb-0">
                          {/* <h4 className="font-bold text-[17px] mb-3">
                            Compilation
                          </h4> */}
                          <table className="w-full text-start">
                            <thead className=" text-black">
                              <tr>
                                {/* <th className="text-sm">
                                  <span className="relative -top-1.5">
                                    Source
                                  </span>
                                </th> */}
                                <th className="text-sm text-center">
                                  <span className="relative -top-1.5">
                                    DESCRIPTION
                                  </span>
                                </th>
                                <th className="text-sm">
                                  <span className="relative -top-1.5">
                                    SIZE
                                  </span>
                                </th>
                                <th className="text-sm">
                                  <span className="relative -top-1.5">
                                    QUANTITY
                                  </span>
                                </th>
                                <th className="text-sm">
                                  <span className="relative -top-1.5">
                                    PRICE
                                  </span>
                                </th>
                                <th className="text-sm text-end">
                                  <span className="relative -top-1.5">
                                    TOTAL
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {group.items.map((item, idx) => (
                                <tr key={idx}>
                                  {/* <td className="text-xs">{item.source}</td> */}
                                  <td className="text-xs">{item.reference}</td>
                                  <td className="text-xs">{item.size}</td>
                                  <td className="text-xs pl-3">{item.quantity}</td>
                                  <td className="text-xs">
                                    {item.price?.toFixed(2)}
                                  </td>
                                  <td className="text-xs text-end">
                                    {item.totalPrice?.toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td colSpan="2" className="font-bold">
                                </td>
                                <td>
                                  {group.items.reduce(
                                    (acc, item) => acc + item.quantity,
                                    0
                                  )}
                                </td>
                                <td>
                                  {group.items
                                    .reduce((acc, item) => acc + item.price, 0)
                                    ?.toFixed(2)}
                                </td>
                                <td>
                                  {group.items
                                    .reduce(
                                      (acc, item) => acc + item.totalPrice,
                                      0
                                    )
                                    ?.toFixed(2)}
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <p className="pb-2">No field copies available.</p>
                    )}
                    <table className="w-full text-xs">
                      <tbody>
                        {laborData
                          .filter((labor) => labor.totalPrice !== 0)
                          .map((labor) => {
                            return (
                              <tr className="">
                                <td className="">
                                  <p>{labor.jobType?.toUpperCase()} LABOR</p>
                                </td>
                                <td className="text-end">
                                  {labor.totalPrice?.toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="">
                    <h4 className="text-[17px] font-semibold text-center mb-6">
                      INVOICE SUMMARY
                    </h4>
                  </div>
                  {/* Compiled data by Job Type */}
                  <div className="mt-1 text-sm">
                    {materialData.map((material) => {
                      return (
                        <div className="flex justify-between mt-1 capitalize">
                          <span>
                            <b>{material.jobType?.toUpperCase()} MATERIAL</b>
                          </span>
                          <span>
                            <b>$</b> {material.totalPrice?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                    {laborData
                      .filter((labor) => labor.totalPrice !== 0)
                      .map((labor) => {
                        return (
                          <div className="flex justify-between mt-1">
                            <span>
                              <b>{labor.jobType?.toUpperCase()} LABOR</b>
                            </span>
                            <span>
                              <b>$</b> {labor.totalPrice?.toFixed(2)}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </>

                {/* Invoice Summary */}
                <div className="w-full mt-3 text-sm">
                  <div className="text-sm">
                    {/* <div className="flex justify-between">
                      <span>TOTAL LABOR COST</span>
                      <span>
                        <b>$</b> {laborTotal?.toFixed(2)}
                      </span>
                    </div>
                    <div className="flex justify-between my-2">
                      <span>Total Material Cost</span>
                      <span>
                        <b>$</b> {materialsTotal?.toFixed(2)}
                      </span>
                    </div> */}
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>SUBTOTAL</span>
                      <span>
                        <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>TAXABLE AMOUNT</span>
                      <span>
                        <b>$</b>{" "}
                        {((taxPercent * taxableAmount) / 100)?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>GRAND TOTAL</span>
                      <span>
                        <b>$</b>{" "}
                        {(
                          (taxPercent * taxableAmount) / 100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Signature & Date Fields */}
                {false && (
                  <div className="mt-3">
                    <div className="">
                      <span className="text-sm">Approved by</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                      <span className="text-sm ml-2">Date</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                    </div>
                    <div className="mt-2">
                      <span className="text-sm">Approved by</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                      <span className="text-sm ml-2">Date</span>
                      <input
                        type="text"
                        className="border-b border-black ml-2 w-[150px] outline-none"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-6 p-6">
              {/* Project Data */}
              {!hideFieldCopy ? (
                <div className="flex flex-col md:flex-row gap-6 justify-around">
                  <div className="flex flex-col w-[280px]">
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Customer Name</h6>
                      <p>{formData?.customerName}</p>
                    </div>
                    {formData && formData?.customerEmail && (
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">Email</h6>
                        <p>{formData?.customerEmail}</p>
                      </div>
                    )}

                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Job Address</h6>
                      <input
                        type="text"
                        value={jobAddress}
                        className="border-0 border-black outline-none mt-1.5 tracking-wide bg-transparent h-[40px] pb-1"
                        onChange={(e) => {
                          setJobAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">
                        Description of work
                      </h6>
                      <textarea
                        value={description}
                        className="border-0 border-black outline-none resize-none bg-transparent w-[250px] mt-1.5 tracking-wide"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex flex-col w-[290px]">
                    <div className="p-2">
                      <img
                        src={fng_logo}
                        className="h-[100px] w-[150px]"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-[280px]">
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">F&G INC</h6>
                      <p>{fgAddress}</p>
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Customer Phone</h6>
                      <p>{formData?.customerPhone}</p>
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Date</h6>
                      <p>{formatDate(Date.now())}</p>
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Project Codes</h6>
                      {projectData.map((data) => {
                        return <p key={data._id}>{data.projectCode}</p>;
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row gap-6 justify-around">
                  <div className="flex flex-col w-[300px]">
                    {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Date</h6>
                    <p>
                      {formData?.projectStartDate
                        ? formatDate(formData.projectStartDate)
                        : ""}
                    </p>
                  </div> */}
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Customer Name</h6>
                      <p>{formData?.customerName}</p>
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">Job Address</h6>
                      <input
                        type="text"
                        // placeholder="Enter job address"
                        value={jobAddress}
                        className="border-b border-black outline-none mt-1.5 tracking-wide bg-transparent h-[40px] pb-1"
                        onChange={(e) => {
                          setJobAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">
                        Description of work
                      </h6>
                      <textarea
                        value={description}
                        className="border-b border-black outline-none resize-none bg-transparent w-[400px] mt-1.5 tracking-wide"
                        // placeholder="Enter description"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      {/* <input type="text" placeholder="Enter job address" value= className="border-b border-black outline" /> */}
                    </div>
                  </div>
                  <div className="flex flex-col w-[300px]">
                    {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Project Code</h6>
                    <p>{formData.projectCode}</p>
                  </div> */}
                    {/* <div className="p-2">
                    <h6 className="font-bold text-[17px]">Job Type</h6>
                    <p>Lnadsc</p>
                  </div> */}
                    {formData && formData?.customerEmail && (
                      <div className="p-2">
                        <h6 className="font-bold text-[17px]">Email</h6>
                        <p>{formData?.customerEmail}</p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-[300px]">
                    <div className="p-2">
                      <h6 className="font-bold text-[17px]">F&G INC</h6>
                      <p>{fgAddress}</p>
                    </div>
                  </div>
                </div>
              )}

              {/* Compiled data */}
              <>
                <div className="w-full mt-10 text-[15px] overflow-x-auto">
                  {!loading ? (
                    categorizedFieldCopies.length > 0 ? (
                      categorizedFieldCopies.map((group, index) => (
                        <div key={index} className="mb-8">
                          <h4 className="font-bold text-lg mb-2">
                            Compilation
                          </h4>
                          <table className="w-full table table-striped text-start">
                            <thead className="bg-[#00613e] text-white">
                              <tr>
                                {/* <th>Source</th> */}
                                <th>Name</th>
                                <th>Size</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {group.items.map((item, idx) => (
                                <tr key={idx}>
                                  {/* <td>{item.source}</td> */}
                                  <td>{item.reference}</td>
                                  <td>{item.size}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.price?.toFixed(2)}</td>
                                  <td>{item.totalPrice?.toFixed(2)}</td>
                                </tr>
                              ))}
                              {laborData
                                .filter((labor) => labor.totalPrice !== 0)
                                .map((labor) => {
                                  return (
                                    <tr className="">
                                      <td className="">
                                        <p>{labor.jobType} Labor</p>
                                      </td>
                                      <td colSpan={3}></td>
                                      <td className="text-start">
                                        {labor.totalPrice?.toFixed(2)}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      ))
                    ) : (
                      <p className="pb-2">No field copies available.</p>
                    )
                  ) : (
                    <p className="pb-2">Loading ...</p>
                  )}
                </div>
                <div className="">
                  <h4 className="text-lg font-semibold text-center mb-6">
                    Invoice Summary
                  </h4>
                </div>
                {/* Compiled data by Job Type */}
                <div className="mt-6 mb-4">
                  {materialData.map((material) => {
                    return (
                      <div className="flex justify-between mt-1 capitalize">
                        <span>
                          <b>{material.jobType} MATERIAL</b>
                        </span>
                        <span>
                          <b>$</b> {material.totalPrice?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
                  {laborData
                    .filter((labor) => labor.totalPrice !== 0)
                    .map((labor) => {
                      return (
                        <div className="flex justify-between mt-1">
                          <span>
                            <b>{labor.jobType} Labor</b>
                          </span>
                          <span>
                            <b>$</b> {labor.totalPrice?.toFixed(2)}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </>

              {/* Invoice Summary */}

              <div className="">
                {/* <div className="">
                  <h4 className="text-lg font-semibold text-center mb-6">
                    Work Summary
                  </h4>
                </div> */}
                <div className="">
                  {/* <div className="flex justify-between">
                    <span>Total Labor Cost</span>
                    <span>
                      <b>$</b> {laborTotal?.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between my-2">
                    <span>Total Material Cost</span>
                    <span>
                      <b>$</b> {materialsTotal?.toFixed(2)}
                    </span>
                  </div> */}
                  <hr />
                  <div className="flex justify-between my-2">
                    <span>SubTotal</span>
                    <span>
                      <b>$</b> {(materialsTotal + laborTotal)?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>

              {!loading ? (
                creditAmount < laborTotal + materialsTotal ? (
                  <div className="">
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Credits</span>
                      <span>
                        <b>$</b> {creditAmount?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Taxable Amount</span>
                      <span>
                        <b>$</b>{" "}
                        {/* {((taxPercent * taxableAmount) / 100)?.toFixed(2)} */}
                        {(
                          (taxPercent * (taxableAmount - creditAmount)) /
                          100
                        )?.toFixed(2)}
                      </span>
                    </div>
                    <hr />
                    <div className="flex justify-between my-2">
                      <span>Grand Total</span>
                      <span>
                        <b>$</b>{" "}
                        {/* {(
                          (taxPercent * taxableAmount) / 100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)} */}
                        {(
                          (taxPercent * (taxableAmount - creditAmount)) / 100 +
                          (materialsTotal + laborTotal)
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="h-[100px] w-full flex justify-center items-center">
                    Please ensure the credits are less than the Sub Total.
                  </div>
                )
              ) : (
                <div className="h-[100px] w-full flex justify-center items-center">
                  Loading ...
                </div>
              )}

              {/* {taxableAmount}
              <br />
              {creditAmount} */}
           
            </div>
            <div className="w-full mb-10 text-[15px] flex justify-end flex-col md:flex-row gap-4 p-6">
              <button
               className={`bg-[#00613e] text-white py-1 px-6 md:mr-4 mr-0 ${
                creditAmount > laborTotal + materialsTotal
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
                type="button"
                data-toggle="modal"
                data-target="#exampleModalCenter_saveAs"
                data-dismiss="modal"
                disabled={
                  creditAmount > laborTotal + materialsTotal
                }
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}


